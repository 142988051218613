import LanguageIcon from "@mui/icons-material/Language";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"; // Functional Components

export default function LanguageDropdown() {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "zh-HK" : "en");

    localStorage.setItem("locale", i18n.language === "en" ? "zh-HK" : "en");
  };

  return (
    <Box component={"div"}>
      <Button
        size="large"
        aria-label="switch language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => {
          i18n.changeLanguage(i18n.language === "en" ? "zh-HK" : "en");
        }}
        color="inherit"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box pt={0.5} px={0.5}>
            <LanguageIcon />
          </Box>

          <Typography>{i18n.language === "en" ? "繁體中文" : "ENG"}</Typography>
        </Box>
      </Button>
    </Box>
  );
}
