import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function WaitingScan() {
  const { t, i18n } = useTranslation();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        gap: 2,
        backgroundColor: "#000",
      }}
    >
      <Box
        component={"video"}
        autoPlay
        loop
        muted
        playsInline
        src="/assets/videos/scan-bg.mp4"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          objectFit: "cover",
          width: "100%",
          height: "100%",
          backgroundColor: "black",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 6,
          zIndex: 1,
        }}
      >
        <Box
          component={"img"}
          src={"/assets/nars-logo-white.png"}
          sx={{
            height: 85,
          }}
          alt={"logo"}
        />

        <Box
          sx={{
            display: i18n.language === "en" ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="h2" pt={2}>
            Unleash Your{" "}
          </Typography>
          <Box
            component={"img"}
            src={"/assets/explicit_lockup.png"}
            alt={"explicit_lockup"}
            width={450}
          />
        </Box>

        <Box
          sx={{
            display: i18n.language === "en" ? "none" : "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="h2" pt={2} pr={1}>
            掀開{" "}
          </Typography>
          <Box
            component={"img"}
            src={"/assets/explicit_lockup.png"}
            alt={"explicit_lockup"}
            width={450}
          />
          <Typography variant="h2" pt={2}>
            真我情書
          </Typography>
        </Box>

        <Box
          component={Typography}
          variant="h4"
          sx={{
            textAlign: "center",
            whiteSpace: "pre-line",
            lineHeight: 1.5,
          }}
          dangerouslySetInnerHTML={{ __html: t("exp_caption") }}
        />

        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            py: 4,
          }}
        >
          <Box
            component={"img"}
            src="/assets/ios-qr-scanner.png"
            sx={{ height: 200, width: 200 }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" whiteSpace={"nowrap"}>
              {t("instruction")}
            </Typography>
          </Box>
        </Box>
        <KeyboardDoubleArrowDownIcon sx={{ fontSize: 48 }} />
      </Box>
    </Box>
  );
}
