import { Box, Collapse, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function WaitingSend() {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        gap: 2,
        backgroundColor: "#000",
        overflow: "hidden",
      }}
    >
      <Box
        component={"video"}
        autoPlay
        loop
        muted
        playsInline
        src="/assets/videos/send-bg.mp4"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          objectFit: "cover",
          width: "100%",
          height: "100%",
          backgroundColor: "black",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          zIndex: 1,
        }}
      >
        <Box
          component={"img"}
          src="/assets/nars-logo-white.png"
          sx={{
            p: 8,
          }}
        />

        <Box
          sx={{
            p: 20,
            pt: 40,
          }}
        >
          <Collapse in={show} orientation="horizontal" timeout={1500}>
            <Typography
              variant="h3"
              sx={{
                color: "white",
                textAlign: "center",
                whiteSpace: "pre-wrap",
                lineHeight: 1.5,
                width: 800,
              }}
            >
              {t("toss_the_letter_to_unleash_innerself")}
            </Typography>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}
