import axios from "../helper/AxiosHelper";

const checkCode = async code => {
  const response = await axios.get(`/event/check-qr-code?code=${code}`);

  const result = response.data.result;

  if (result.error) {
    return {
      error: result.error,
      message: result.message,
    };
  }

  return {
    name: result.name,
    surveyResult: result.survey_result,
  };
};

const submit = async (code, file) => {
  const formData = new FormData();
  formData.append("code", code);
  formData.append("image", file);

  const response = await axios.post("/event/submit", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const result = response.data.result;

  if (result.error) {
    return {
      error: result.error,
      message: result.message,
    };
  }

  return {
    uploaded: result.uploaded,
    uploadedFile: result.uploaded_file,
  };
};

export const HomeApi = {
  checkCode,
  submit,
};
