const palette = {
  type: "light",

  primary: {
    main: "#e6001e",
  },
  secondary: {
    main: "#000000",
  },
  success: {
    main: "#589619",
  },
  info: {
    main: "#2B69C5",
  },
  error: {
    main: "#C72929",
  },
  text: {
    primary: "#FFF",
  },
  background: {
    white: "#ffffff",
    grey: "#E5E5E5",
    warm: "#F7F4D6",
    black: "#333333",
  },
  color: {
    1: "#F2F2F2",
    2: "#966419",
    3: "#199696",
    4: "#589619",
    5: "#585858",
  },
};

export default palette;
