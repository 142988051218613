import palette from "../palette.js";

const Element = {
  variants: [
    {
      props: {
        variant: "outlined",
        color: "primary",
      },
      style: {
        border: `1px solid white`,
        color: "white",
        "&:hover": {
          border: `1px solid white`,
          color: "white",
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      padding: "10px 30px",
    },
  },
};

export default Element;
