const Element = {
  styleOverrides: {
    root: {
      borderColor: "#333",
      borderBottomWidth: "1px",
    },
  },
};

export default Element;
