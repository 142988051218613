import Home from "../views/Home";
import LedScreen from "../views/LedScreen";

const HomeRoute = {
  // element: <AppContainer />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/led",
      element: <LedScreen />,
    },
    // {
    //   path: "*",
    //   element: <Home />,
    // },
  ],
};

export default HomeRoute;
