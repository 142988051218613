import Components from "./components";
import Palette from "./palette.js";
import Typography from "./typography.js";

const themeOptions = {
  palette: Palette,
  typography: Typography,
  components: Components,
};

export default themeOptions;
