import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

export default function LipstickDetail({ code, name, surveyResult, lipstick }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: "url('/assets/detail-background.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        gap: 2,
        backgroundColor: "#000",
        overflow: "hidden",
      }}
    >
      <Box
        component={"img"}
        src="/assets/nars-logo-white.png"
        sx={{
          p: 8,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          p: 4,
        }}
      >
        <Typography variant="h3" whiteSpace={"pre-wrap"}>
          {t("your_love_letter")}
        </Typography>

        <Typography variant="h3" whiteSpace={"pre-wrap"}>
          {t("dear", { name: name })}
        </Typography>

        <Typography
          variant="h2"
          whiteSpace={"pre-wrap"}
          sx={{
            p: 2,
            px: 5,
            textAlign: "center",
            color: lipstick?.color,
            lineHeight: "1.5em",
          }}
        >
          {t(`lipstick.${lipstick?.letter}`)}
        </Typography>

        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box
            component={"img"}
            src={lipstick?.image}
            sx={{
              height: 800,
              py: 2,
            }}
          />
        </Box>

        <Typography
          variant="h2"
          whiteSpace={"pre-wrap"}
          sx={{
            color: lipstick?.color,
            fontFamily: "Helvetica Neue LT Std, sans-serif",
          }}
        >
          {lipstick?.name}
        </Typography>
      </Box>
    </Box>
  );
}
