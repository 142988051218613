import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Button, Collapse, Typography, Zoom } from "@mui/material";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import { useSwipeable } from "react-swipeable";
import { HomeApi } from "../../api/HomeApi";
import { timeout } from "../../helper/UnitsHelper";
import FullScreenLoading from "../FullScreenLoading";
export default function Signature({ code, name, surveyResult, lipstick, onUpdate }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const viewRef = useRef(null);
  const screenshot = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [paperHeight, setPaperHeight] = useState(40);
  const [envelopeTopAngle, setEnvelopeTopAngle] = useState(180);
  const [envelopeTopZIndex, setEnvelopeTopZIndex] = useState(1);
  const [envelopeScale, setEnvelopeScale] = useState(0.1);
  const [envelopeTranslateY, setEnvelopeTranslateY] = useState(0);
  const [enabledSwipe, setEnabledSwipe] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handlers = useSwipeable({
    preventScrollOnSwipe: true,
    onSwiped: eventData => handleSwipe(eventData),
  });

  useEffect(() => {
    console.log(code, name, surveyResult);

    setTimeout(() => {
      setIsVisible(true);
    }, 100);

    setTimeout(() => {
      envelopeOpen();
    }, 1000);
  }, [code, name, surveyResult]);

  const captureSignature = async () => {
    const element = viewRef.current;

    const canvas = await html2canvas(element, {
      backgroundColor: null,
      scale: 1,
    });

    const blob = await new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        resolve(blob);
      });
    });

    console.log(blob);

    screenshot.current = blob;
    setConfirmed(true);
    envelopeClose();

    onUpdate({
      step: 3,
      code: code,
      name: name,
      surveyResult: surveyResult,
      lipstick: lipstick,
    });
  };

  const submitSignature = async () => {
    try {
      setIsLoading(true);
      const blob = screenshot.current;
      const response = await HomeApi.submit(code, blob);

      console.log(response);

      if (response.error) {
        console.log(response.message);
        alert(response.message);
        return;
      } else if (response.uploaded) {
        onUpdate({
          step: 4,
          code: code,
          name: name,
          surveyResult: surveyResult,
          lipstick: lipstick,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const envelopeOpen = async () => {
    setEnvelopeScale(1);
    await timeout(100);
    setEnvelopeTopZIndex(1);
    await timeout(100);
    setEnvelopeTopAngle(0);
    await timeout(400);
    setEnvelopeTopZIndex(0);
    await timeout(400);
    setPaperHeight(100);
    await timeout(400);
    setShowMessage(false);
    await timeout(400);
    setIsReady(true);
  };

  const envelopeClose = async () => {
    setPaperHeight(40);
    await timeout(500);
    setEnvelopeTopZIndex(1);
    await timeout(400);
    setEnvelopeTopAngle(180);
    await timeout(400);
    setEnvelopeScale(0.8);
    await timeout(400);
    setEnabledSwipe(true);
    await timeout(400);
    setShowMessage(true);
  };

  const envelopeFly = async () => {
    setEnvelopeTranslateY(-5000);

    submitSignature();
  };

  const handleSwipe = async eventData => {
    if (!enabledSwipe) {
      return;
    }

    if (eventData.dir === "Up") {
      envelopeFly();
    }
  };

  return (
    <Box
      {...handlers}
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.8s",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "#000",
        overflow: "hidden",
        backgroundImage: "url('/assets/Ipad-background.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <FullScreenLoading open={isLoading} />

      <Box
        component={"img"}
        src="/assets/nars-logo-white.png"
        sx={{
          position: "fixed",
          top: 0,
          p: 4,
          width: 200,
        }}
      />

      <Box
        sx={{
          p: 8,
          textAlign: "center",
          top: 200,
          position: "fixed",
          zIndex: 1,
        }}
      >
        <Collapse in={showMessage} orientation="horizontal" timeout={500} unmountOnExit>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Typography variant="h5" whiteSpace={"pre-wrap"} width={250}>
              {t("swipe_up_to_toss")}
            </Typography>

            <KeyboardDoubleArrowUpIcon
              sx={{
                fontSize: 48,
              }}
            />
          </Box>
        </Collapse>
      </Box>

      <Box
        ref={viewRef}
        sx={{
          // backgroundColor: "#000",
          // opacity: isVisible ? 1 : 0,
          width: 580,
          height: 850,
          position: "relative",
          transition: "transform 1s",
          transform: `scale(${envelopeScale}) translateY(${envelopeTranslateY}px)`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            component={"img"}
            src="/assets/envelope-top.png"
            sx={{
              width: "100%",
              transformStyle: "preserve-3d",
              transition: "transform 0.8s",
              transformOrigin: "bottom center 0px",
              transform: `rotateX(${envelopeTopAngle}deg)`,
              zIndex: envelopeTopZIndex,
            }}
          />
          <Box component={"img"} src="/assets/envelope-base.png" width={"100%"} />
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            width: "100%",
            transition: "height 0.8s",
            height: `${paperHeight}%`,
            overflow: "hidden",
          }}
        >
          <Box
            component={"img"}
            src="/assets/paper.png"
            sx={{
              px: 4,
              width: "100%",
            }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              top: 24,
              left: "0",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: lipstick?.color || "black",
                textAlign: "center",
                marginBottom: 1,
                whiteSpace: "pre-wrap",
                maxWidth: 400,
              }}
            >
              {t(`lipstick.${lipstick?.letter}`)}
            </Typography>

            <Box component={"img"} src={lipstick?.image} sx={{ width: 300, height: 300 }} />

            <Typography
              variant="h5"
              sx={{
                color: lipstick?.color || "black",
                textAlign: "center",
                marginBottom: 2,
                fontFamily: "Helvetica Neue LT Std, sans-serif",
              }}
            >
              {lipstick?.name}
            </Typography>

            <Box
              sx={{
                borderBottom: `1px solid ${lipstick?.color || "black"}`,
              }}
            >
              <SignatureCanvas
                penColor={lipstick?.color || "black"}
                canvasProps={{ width: 250, height: 80, className: "sigCanvas" }}
              />
            </Box>

            <Typography color="textSecondary" pt={1}>
              {t("signature")}
            </Typography>
          </Box>
        </Box>

        <Box
          component={"img"}
          src="/assets/envelope-body.png"
          sx={{
            position: "absolute",
            pointerEvents: "none",
            bottom: 0,
            right: 0,
            left: 0,
            width: "100%",
          }}
        />
      </Box>

      <Box
        py={2}
        sx={{
          opacity: isReady ? (confirmed ? 0 : 1) : 0,
        }}
      >
        <Button disabled={confirmed} size="large" variant="outlined" onClick={captureSignature}>
          {t("comfirm")}
        </Button>
      </Box>
    </Box>
  );
}
