import { Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SocketErrorAlert from "../components/SocketErrorAlert";
import Result from "../components/home/Result";
import ScanCode from "../components/home/ScanCode";
import Signature from "../components/home/Signature";
import { socket } from "../socket";

export default function Home() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [surveyResult, setSurveyResult] = useState("");
  const [lipstick, setLipstick] = useState();

  const [isSocketConnected, setIsSocketConnected] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    socket.connect();

    socket.on("connect", () => {
      console.log("Connected to server");
      setIsSocketConnected(true);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
      setIsSocketConnected(false);
    });

    i18n.on("languageChanged", lng => {
      socket.emit("language", lng);
    });

    return () => {
      socket.disconnect();
    };
  }, [isMounted]);

  const updateStep = ({ step, code, name, surveyResult, lipstick }) => {
    setStep(step);
    setCode(code);
    setName(name);
    setSurveyResult(surveyResult);
    setLipstick(lipstick);

    socket.emit("events", {
      step,
      code,
      name,
      surveyResult,
      lipstick,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "black",
      }}
    >
      {!isSocketConnected && <SocketErrorAlert />}

      <Fade in={step == 1} timeout={500} unmountOnExit>
        <Box width={"100%"}>
          <ScanCode onUpdate={updateStep} />
        </Box>
      </Fade>

      <Fade in={step == 2 || step == 3} timeout={500} unmountOnExit>
        <Box width={"100%"}>
          <Signature
            code={code}
            name={name}
            surveyResult={surveyResult}
            lipstick={lipstick}
            onUpdate={updateStep}
          />
        </Box>
      </Fade>

      <Fade in={step == 4} timeout={500} unmountOnExit>
        <Box width={"100%"}>
          <Result onUpdate={updateStep} />
        </Box>
      </Fade>
    </Box>
  );
}
