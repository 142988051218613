import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Result({ onUpdate }) {
  const { t } = useTranslation();
  const [showTitle, setShowTitle] = useState(false);
  const [showNextStep, setShowNextStep] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      reset();
    }, 10000);

    setTimeout(() => {
      setShowTitle(true);
    }, 10);

    setTimeout(() => {
      setShowNextStep(true);
    }, 500);
  }, []);

  const reset = () => {
    onUpdate({
      step: 1,
      code: "",
      name: "",
      surveyResult: "",
    });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: "url('/assets/result-background-ipad.jpg')",
        backgroundSize: "contain",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(0, 0, 0, 11)",
      }}
    >
      <Box
        component={"img"}
        src="/assets/nars-logo-white.png"
        sx={{
          p: 4,
          width: 200,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
          p: 5,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Collapse in={showTitle} orientation="horizontal" timeout={1500}>
          <Typography variant="h4" whiteSpace={"pre-wrap"} width={500}>
            {t("successfully_unleashed")}
          </Typography>
        </Collapse>

        <Collapse in={showNextStep} orientation="horizontal" timeout={2000}>
          <Typography variant="h5" px={5} whiteSpace={"pre-wrap"} width={500}>
            {t("next_step")}
          </Typography>
        </Collapse>

        <IconButton
          onClick={() => {
            reset();
          }}
        >
          <KeyboardDoubleArrowRightIcon sx={{ fontSize: 48, color: "white" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
