import { Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import WaitingScan from "../components/led/WaitingScan";
import LipstickDetail from "../components/led/LipstickDetail";
import WaitingSend from "../components/led/WaitingSend";
import Sent from "../components/led/Sent";

export default function LedScreen() {
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [surveyResult, setSurveyResult] = useState("");
  const [lipstick, setLipstick] = useState();

  const [socketUrl, setSocketUrl] = useState(process.env.REACT_APP_API_SERVER_URL);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    const socket = io(socketUrl);
    socket.on("connect", () => {
      console.log("Connected to server");
      setIsSocketConnected(true);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
      setIsSocketConnected(false);
    });

    socket.on("events", data => {
      console.log(data);
      setStep(data.step);
      setCode(data.code);
      setName(data.name);
      setSurveyResult(data.surveyResult);
      setLipstick(data.lipstick);
    });

    socket.on("language", lng => {
      i18n.changeLanguage(lng);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <Fade in={step == 1} timeout={1000} unmountOnExit>
        <Box width={"100%"}>
          <WaitingScan />
        </Box>
      </Fade>

      <Fade in={step == 2} timeout={1500} unmountOnExit>
        <Box width={"100%"}>
          <LipstickDetail code={code} name={name} surveyResult={surveyResult} lipstick={lipstick} />
        </Box>
      </Fade>

      <Fade in={step == 3} timeout={1000} unmountOnExit>
        <Box width={"100%"}>
          <WaitingSend />
        </Box>
      </Fade>

      <Fade in={step == 4} timeout={1000} unmountOnExit>
        <Box width={"100%"}>
          <Sent />
        </Box>
      </Fade>
    </Box>
  );
}
