const typography = {
  // fontFamily: "Helvetica Neue LT Std, MHeiHK, sans-serif",

  h1: {
    fontSize: 96,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 3.84,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },

  h2: {
    fontSize: 60,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 2.4,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },
  h3: {
    fontSize: 48,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 1.92,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },
  h4: {
    fontSize: 40,
    fontWeight: 400,
    lineHeight: 1.2,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },
  h5: {
    fontSize: 30,
    fontWeight: 400,
    lineHeight: 1.2,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },
  h6: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.2,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Helvetica Neue LT Std, sans-serif",
  },
};

export default typography;
