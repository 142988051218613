import { Alert } from "@mui/material";

export default function SocketErrorAlert() {
  return (
    <Alert
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 100,
      }}
      severity="error"
    >
      Socket is not connected
    </Alert>
  );
}
