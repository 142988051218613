import theme from "../styles/theme";

const getTheme = locale => {
  if (locale === "en") {
    return theme;
  }

  if (locale === "zh-HK") {
    // override theme
    const zhTheme = {
      ...theme,
      typography: {
        ...theme.typography,

        h1: {
          ...theme.typography.h1,
          fontFamily: "MHeiHK, sans-serif",
        },
        h2: {
          ...theme.typography.h2,
          fontFamily: "MHeiHK, sans-serif",
        },
        h3: {
          ...theme.typography.h3,
          fontFamily: "MHeiHK, sans-serif",
        },
        h4: {
          ...theme.typography.h4,
          fontFamily: "MHeiHK, sans-serif",
        },
        h5: {
          ...theme.typography.h5,
          fontFamily: "MHeiHK, sans-serif",
        },
        h6: {
          ...theme.typography.h6,
          fontFamily: "MHeiHK, sans-serif",
        },
        body1: {
          ...theme.typography.body1,
          fontFamily: "MHeiHK, sans-serif",
        },
      },
    };
    // console.log("zhTheme", zhTheme);
    return zhTheme;
  }

  // default
  return theme;
};

export const ThemeHelper = {
  getTheme,
};
