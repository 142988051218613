import { CssBaseline, ThemeProvider } from "@mui/material";
import Router from "./routes";
import { ThemeHelper } from "./helper/ThemeHelper";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function App() {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);

  useEffect(() => {
    console.log("i18n.language", i18n.language);
    setLocale(i18n.language);
  }, [i18n.language]);

  return (
    <ThemeProvider theme={ThemeHelper.getTheme(locale)}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;
