import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Sent() {
  const { t } = useTranslation();
  const [showTitle, setShowTitle] = useState(false);
  const [showNextStep, setShowNextStep] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowTitle(true);
    }, 10);

    setTimeout(() => {
      setShowNextStep(true);
    }, 500);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: "url('/assets/result-background.jpg')",
        backgroundSize: "contain",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(0, 0, 11)",
        gap: 20,
      }}
    >
      <Box
        component={"img"}
        src="/assets/nars-logo-white.png"
        sx={{
          mt: 8,
          height: 100,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 10,
          p: 10,
          textAlign: "center",
        }}
      >
        <Collapse in={showTitle} orientation="horizontal" timeout={1500}>
          <Typography variant="h2" whiteSpace={"pre-wrap"} width={800}>
            {t("successfully_unleashed")}
          </Typography>
        </Collapse>

        <Collapse in={showNextStep} orientation="horizontal" timeout={2000}>
          <Typography variant="h3" px={5} whiteSpace={"pre-wrap"} lineHeight={1.5} width={800}>
            {t("next_step")}
          </Typography>
        </Collapse>

        <IconButton>
          <KeyboardDoubleArrowRightIcon sx={{ fontSize: 48, color: "white" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
