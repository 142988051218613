import { Box, ButtonBase, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BarcodeScanner } from "react-barcode-scanner";
import "react-barcode-scanner/polyfill";
import { useTranslation } from "react-i18next";
import { HomeApi } from "../../api/HomeApi";
import { LipstickHelper } from "../../helper/LipstickHelper";
import FullScreenLoading from "../FullScreenLoading";
import LanguageDropdown from "../LanguageDropdown";

export default function ScanCode({ onUpdate }) {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [startScan, setStartScan] = useState(true);
  const [facingMode, setFacingMode] = useState("user");

  useEffect(() => {
    onUpdate({
      step: 1,
      code: "",
      name: "",
      surveyResult: "",
      lipstick: undefined,
    });
  }, []);

  const checkCode = async result => {
    try {
      setStartScan(false);
      setIsLoading(true);
      const code = result.rawValue;

      const response = await HomeApi.checkCode(code);

      console.log(response);

      if (response.error) {
        console.log(response.message);
        alert(response.message);
        setStartScan(true);
      } else {
        const surveyResult = response.surveyResult;
        const lipstick = LipstickHelper.getLipstick(surveyResult);

        onUpdate({
          step: 2,
          code: code,
          name: response.name,
          surveyResult: surveyResult,
          lipstick: lipstick,
        });
      }
    } catch (error) {
      setStartScan(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const restartScan = () => {
    setStartScan(false);

    setTimeout(() => {
      setStartScan(true);
    }, 1000);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        gap: 2,
        overflow: "hidden",
        backgroundColor: "#000",
      }}
    >
      <Box
        component={"video"}
        autoPlay
        loop
        muted
        playsInline
        src="/assets/videos/scan-bg-ipad.mp4"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          objectFit: "cover",
          width: "100%",
          height: "100%",
          backgroundColor: "black",
        }}
      />

      <FullScreenLoading open={isLoading} />

      <Box
        sx={{
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
        >
          <LanguageDropdown />
        </Box>

        <Box
          component={"img"}
          src={"/assets/nars-logo-white.png"}
          sx={{
            height: 58,
          }}
          alt={"logo"}
        />

        <Box
          sx={{
            display: i18n.language === "en" ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h5" pt={2}>
            Unleash Your{" "}
          </Typography>
          <Box
            component={"img"}
            src={"/assets/explicit_lockup.png"}
            alt={"explicit_lockup"}
            width={300}
          />
        </Box>

        <Box
          sx={{
            display: i18n.language === "en" ? "none" : "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" pt={2}>
            掀開{" "}
          </Typography>
          <Box
            component={"img"}
            src={"/assets/explicit_lockup.png"}
            alt={"explicit_lockup"}
            width={300}
            sx={{
              pl: 2,
            }}
          />
          <Typography variant="h5" pt={2}>
            真我情書
          </Typography>
        </Box>

        <Box
          component={Typography}
          variant="h6"
          sx={{
            textAlign: "center",
            whiteSpace: "pre-line",
            lineHeight: 1.5,
          }}
          dangerouslySetInnerHTML={{ __html: t("exp_caption") }}
        />

        <Box py={4}>
          {startScan ? (
            <Box
              sx={{
                height: 300,
                width: 300,
                borderRadius: "38px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <ButtonBase
                disabled
                sx={{ height: 300, width: 300, position: "absolute", zIndex: 1 }}
                onClick={() => {
                  setFacingMode(facingMode === "environment" ? "user" : "environment");
                }}
              >
                <Box
                  component={"img"}
                  src="/assets/ios-qr-scanner.png"
                  sx={{ height: "100%", width: "100%" }}
                />
              </ButtonBase>

              <BarcodeScanner
                trackConstraints={{
                  facingMode: facingMode,
                }}
                options={{
                  delay: 2000,
                  formats: ["qr_code"],
                }}
                onCapture={result => {
                  checkCode(result);
                }}
                onError={error => {
                  console.error(error);
                  restartScan();
                }}
              />
            </Box>
          ) : (
            <Box
              component={"img"}
              src="/assets/ios-qr-scanner.png"
              sx={{ height: 300, width: 300 }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
