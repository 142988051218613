const lipstickData = [
  {
    name: "Unauthorized",
    code: 863,
    color: "#cb2c30",
    letter: "863_letter",
    image: "/assets/lipstick/863.png",
  },
  {
    name: "Take It Off",
    code: 866,
    color: "#9e2a2b",
    letter: "866_letter",
    image: "/assets/lipstick/866.png",
  },
  {
    name: "Desirous",
    code: 867,
    color: "#af272f",
    letter: "867_letter",
    image: "/assets/lipstick/867.png",
  },
  {
    name: "No Shame",
    code: 802,
    color: "#ba5b3f",
    letter: "802_letter",
    image: "/assets/lipstick/802.png",
  },
  {
    name: "Body Heat",
    code: 803,
    color: "#a4493d",
    letter: "803_letter",
    image: "/assets/lipstick/803.png",
  },
  {
    name: "Rendez-vous",
    code: 805,
    color: "#9a3324",
    letter: "805_letter",
    image: "/assets/lipstick/805.png",
  },
  {
    name: "Liaison",
    code: 821,
    color: "#cf6f77",
    letter: "821_letter",
    image: "/assets/lipstick/821.png",
  },
  {
    name: "Luscious",
    code: 823,
    color: "#ab5c57",
    letter: "823_letter",
    image: "/assets/lipstick/823.png",
  },
  {
    name: "Dirty Talk",
    code: 822,
    color: "#bd625e",
    letter: "822_letter",
    image: "/assets/lipstick/822.png",
  },
  {
    name: "Undressed",
    code: 826,
    color: "#b6533e",
    letter: "826_letter",
    image: "/assets/lipstick/826.png",
  },
];

const getLipstick = surveyResult => {
  const lipstick = lipstickData.find(lipstick => lipstick.code == surveyResult);

  return lipstick;
};

export const LipstickHelper = {
  getLipstick,
};
